import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Layout from '../../components/Layout';
import Slider from '../../components/Slider';
import Banner from '../../components/Home/Banner';
import Card from '../../components/Home/Card';

import * as styles from './Home.module.scss';

import { getImages } from '../../utils/common';
import Tab from '../../components/Home/Tab';
import Menu from '../../components/Home/Menu';
import { trackingLinkHelper } from '../../utils/tracking-link-helper';

const Home = ({ pageContext, data }) => {
  const { locale } = pageContext;
  const { isMobileView } = useSelector((state) => state.common);
  const { allBanner, allFile, allMenu, allProduct } = data;
  const images = getImages(allFile.nodes);
  const { t, i18n } = useTranslation();

  const sliderSettings = {
    autoplay: true,
    autoplaySpeed: 7000,
    customPaging: (i) => {
      return <div>{i + 1}</div>;
    },
    dotsClass: styles.Dots,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          customPaging: () => {
            return <div className={styles.Dot}></div>;
          },
        },
      },
    ],
  };

  const findProduct = (id) => {
    return allProduct.nodes.find((node) => {
      return node.slug === id;
    });
  };

  const handleSliderClick = (image, url) => {
    //console.log('handleSliderClick');

    const src = image.src;
    const lastPart = src.split('/').pop();

    window.dataLayer.push({
      event: 'uaevent',
      event_name: 'slider_button_click',
      eventAction: 'select::slider navigation',
      eventCategory: 'homepage',
      eventLabel: `${lastPart}::${url}`,
      cta_name: lastPart,
      link_url: trackingLinkHelper(url),
      site_language: i18n.language,
      page_category: 'homepage',
      brand: 'BIO',
      country: 'hk',
    });
  };



  return (
    <Layout
      padding={false}
      seoSettings={{ title: t('home_page') }}
      hasBreadcrumb={false}
    >
      {isMobileView && <Tab gender={'women'} />}
      <Slider settings={sliderSettings}>
        {allBanner.nodes.map((item) => {
          return (
            <Banner
              key={item.id}
              path={item.content.url}
              image={item.content.remoteImage.childImageSharp.fluid}
              onClick={() => { handleSliderClick(item.content.remoteImage.childImageSharp.fluid, item.content.url) }}
            />
          );
        })}
      </Slider>
      {isMobileView && <Menu list={allMenu.nodes[0].types} />}
      {!isMobileView && (
        <div
          className={`row no-gutters justify-content-between ${styles.CardsContainer}`}
        >
          <div className={styles.Cards}>
            <Card
              title={"whats_new"}
              titleColor="#fff"
              //description={findProduct('250').content.name}
              description={
                locale === 'zh'
                  ? "<span>CERA REPAIR</span><br>抗衰老屏障修復面霜"
                  : "<span>CERA REPAIR<br>BARRIER CREAM</span>"
              }
              descriptionColor="#ffffff"
              bgImage={images['bg-whats-new']}
              button={'discover_more'}
              //prodImage={findProduct('250').remoteImage.childImageSharp.fluid}
              path={`/femme/product/${findProduct('250').slug}`}
              alt={t('whats_new')}
            />
            <Card
              bgImage={
                locale === 'zh'
                  ? images['bg-about-biotherm-2']
                  : images['bg-about-biotherm-2_en']
              }
              button={'find_out_more'}
              path={'/about-biotherm'}
              alt={t('about_biotherm')}
            />
          </div>
          <div className={styles.Cards}>
            <Card
              title="most_loved"
              titleColor="#163e79"
              //description={findProduct('243').content.name}
              description={
                locale === 'zh'
                  ? "<b><span>LIFE PLANKTON™</span><br>奇蹟再生嫩肌精華</b>"
                  : "<b><span>LIFE PLANKTON™<br>ELIXIR</span></b>"
              }
              descriptionColor="#163e79"
              bgImage={images['bg-most-loved']}
              button={'discover_more'}
              //prodImage={findProduct('243').remoteImage.childImageSharp.fluid}
              path={`/femme/product/${findProduct('243').slug}`}
              alt={t('most_loved')}
            />
            <Card
              bgImage={images['bg-homme']}
              button={false}
              path={`/`}
              alt={t('mens_care')}
            />
          </div>
          <div className={styles.Cards}>
            <Card
              title={'in_the_spot'}
              //description={findProduct('199').content.name}
              titleColor="#163e79"
              description={
                locale === 'zh'
                  ? "<b><span>LIFE PLANKTON™</span><br>皇牌奇蹟水</b>"
                  : "<b><span>LIFE PLANKTON™<br>ESEENCE</span></b>"
              }
              descriptionColor="#163e79"
              bgImage={images['bg-in-the-spot']}
              button={'discover_more'}
              //prodImage={findProduct('199').remoteImage.childImageSharp.fluid}
              path={`/femme/product/${findProduct('199').slug}`}
              alt={t('in_the_spot')}
            />
            {/* <Card
              bgImage={
                locale === 'zh' ? images['bg-sample'] : images['bg-sample_en']
              }
              button={false}
              alt={t('try_it_now')}
            /> */}

          </div>
        </div>
      )}
    </Layout>
  );
};

export default Home;

export const pageQuery = graphql`
  query($locale: String, $gender: String) {
    allBanner(sort: { fields: sequence }, filter: { gender: { eq: $gender } }) {
      nodes {
        id
        unique_id
        content(locale: $locale) {
          url
          image
          remoteImage {
            childImageSharp {
              fluid(quality: 100,maxWidth:980) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    allProduct(filter: { unique_id: { in: [250, 243, 199] } }) {
      nodes {
        slug
        remoteImage {
          childImageSharp {
            fluid(maxWidth: 187) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        content(locale: $locale) {
          name
        }
      }
    }

    allFile(filter: { relativeDirectory: { eq: "images/home" } }) {
      nodes {
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }

    allMenu(filter: { slug: { eq: $gender } }) {
      nodes {
        slug
        types {
          id
          slug
          localizations {
            en {
              name
            }
            zh {
              name
            }
          }
          categories {
            slug
            localizations {
              en {
                name
              }
              zh {
                name
              }
            }
          }
          needs {
            slug
            localizations {
              en {
                name
              }
              zh {
                name
              }
            }
          }
          series {
            slug
            localizations {
              en {
                name
              }
              zh {
                name
              }
            }
          }
        }
      }
    }
  }
`;


